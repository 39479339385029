/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        $('.modal-trigger').on('click', function(e) {
          console.log($(this).data('target'));
          $($(this).data('target')).addClass('active');
        });
        $('.modal-alt-close').on('click', function(e) {
          $(this).parent().removeClass('active');
        })
        $('.contact-floating .close').on('click', function(e) {
          $('.contact-floating').fadeOut();
        });

        $('.skip-link').on('click', function(e) {
          $('html, body').animate({
            scrollTop: $($(this).attr('href')).offset().top
          }, 1000);
          e.preventDefault();
        });

        window.addEventListener("scroll",function() { 
         if(window.scrollY > 100) {
          $('.nav-primary').addClass('visible');
         }
         else {
          $('.nav-primary').removeClass('visible');
         }
        },false);

        mapboxgl.accessToken = 'pk.eyJ1Ijoiam9lbGdhYnJpIiwiYSI6ImNqM3FhZ3cwcTAwMzkzM3J0ZmJpY2xvaGMifQ.YRipXrUbDKUhnmUVTWFVhw';
        var location = $('#contact-information-map').data('location').split(',');
        var map = new mapboxgl.Map({
          center: location,
          zoom: 13,
          container: 'contact-information-map',
          style: 'mapbox://styles/mapbox/streets-v10',
          scrollZoom: false,
          dragPan: false,
          dragRotate: false,
          // style: 'mapbox://styles/mapbox/light-v9',
        });
        var marker = new mapboxgl.Marker().setLngLat(location);
        marker._offset = { x:-30, y:-60 };
        marker.addTo(map);

        var nav = new mapboxgl.NavigationControl();
        map.addControl(nav, 'top-left');
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        var lastScroll = window.scrollY;
        var dir = 'down';
        var scroll = 0;
        window.addEventListener("scroll",function() { 
          if (lastScroll < window.scrollY) { scroll = $('.home-gallery').scrollLeft() + 5; }
          else { scroll = $('.home-gallery').scrollLeft() - 5; }
          lastScroll = window.scrollY
          if(window.scrollY > 500) {
            
            $('.home-gallery').animate({
              scrollLeft: scroll,
            }, 5);
          }
          
        },false);
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
